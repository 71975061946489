<template>
	<Popup name="bacs"
	text="<h3>Les bacs de stockage</h3><br/><p>Les plus gros bacs peuvent contenir 90 000 m3 de produit. Ils mesurent 72 m de diamètre et 20 m de haut. Ils sont équipés soit d’un toit fixe soit d’un toit flottant pour améliorer la sécurité (éviter le développement de phase gazeuse) et entourés d’une cuvette de rétention.</p><br/><h3>Innovation</h3><p>Des robots sont utilisés pour les opérations d’inspection. Le gain de temps et de sécurité est important. Auparavant ce type d’opérations nécessitait de monter sur des échafaudages pour des contrôles humains. Ces robots scanners sont plus efficients pour la détection de corrosion et permettent d’anticiper plus précisément les travaux à venir.</p>"
	video="static/video/bacs.mp4"
	sticker="static/logo-total.jpg"
	legend="Découvrez les produits pétroliers stockés à la raffinerie dans cette vidéo">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/bacs_zbbtwl_c_scale_w_350.jpg 350w,
		img/popup/bacs_zbbtwl_c_scale_w_720.jpg 720w,
		img/popup/bacs_zbbtwl_c_scale_w_970.jpg 970w"
		data-src="img/popup/bacs_zbbtwl_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
